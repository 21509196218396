import React, { Component, useState } from 'react';
import {
    Card,
    CardImg,
    CardBody,
    CardFooter,
    CardTitle,
    CardSubtitle,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Popover,
    PopoverHeader,
    PopoverBody,
} from 'reactstrap';
import ReactGA from 'react-ga';
import laura from '../images/board/laura.jpg';
import brian from '../images/board/brian.jpg';
import neil from '../images/board/neil.jpg';
import shane from '../images/board/shane.jpg';
import charles from '../images/board/charles.jpg';
import karl from '../images/board/karl.jpg';
import kenny from '../images/board/kenny.jpg';
import richard from '../images/board/richard.jpg';
import jack from '../images/board/jack.jpg';
import niamh from '../images/board/niamh.jpg';
import reailtin from '../images/board/reailtin.jpg';
import cait from '../images/board/cait.jpg';
import sarah from '../images/board/sarah.jpg';
import emma from '../images/board/emma.jpg';
import sorcha from '../images/board/sorcha.jpg';
import linkedin from '../images/icons/linkedin.svg';


export default class Board extends Component {

    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: null, // Not tied to any specific member initially
        };

        this.togglePopover = this.togglePopover.bind(this);
    }

    toggle = () => {
        const [modal, setModal] = useState(false);
        setModal(!modal);
    }

    togglePopover(key) {
        this.setState((prevState) => ({
            popoverOpen: prevState.popoverOpen === key ? null : key,
        }));
    }

    getBoardMemberBio = (id) => {
        const board = this.getBoard();
        return board[id].bio === "" ? "Bio coming soon..." : board[id].bio;
    }

    getBoard() {
        return {
            "1":
            {
                name: "Laura O’Callaghan",
                linkedin: "https://uk.linkedin.com/in/laurao2",
                position: "Non-Executive Director",
                photo: laura,
                company: (<a href='https://wise.com/'>Wise</a>),
                industry: "Fintech",
                bio: ""
            },
            "2": {
                name: "Kenneth O’Connor",
                linkedin: "https://www.linkedin.com/in/kenneth-o-connor-b9474823/",
                position: "Non-Executive Director",
                photo: kenny,
                company: (<a href='https://e-frontiers.ie/'>e-Frontiers</a>),
                industry: "Recruitment",
                bio: "Kenneth (Kenny) was elected as Vice-Chair of Libs in 2019, taking over the position from Shane Kelly following his relocation to Taiwan. Kenny leads e-Frontiers’ UK operations and has been a vocal member of the Libs community and promient board member for several years."
            },
            "3":
            {
                name: "Shane Kelly",
                linkedin: "https://uk.linkedin.com/in/shanekelly1",
                position: "Non-executive Director",
                photo: shane,
                company: (<a href='https://www.macquarie.com/'>Macquarie</a>),
                industry: "Renewable Energy",
                bio: "Shane joined the board of the London Irish Business Society in January 2012. In recognition for his years of passionate commitment to LIBS Shane was elected as Vice Chair for LIBS in June 2018." +
                    "Shane works in the wind energy business of Swedish State Utility Vattenfall, where he is Head of Construction within the Operational Readiness division.Here he heads up the international team responsible for the delivery of new onshore and offshore assets into full operation at one of the world’s largest wind developers.Shane has previously worked for Enterprise Ireland’s London office supporting the UK market entry and strategic business development of Irish SMEs in the energy and built environment sectors." +
                    "Shane holds a BSc. (Hons) in Quantity Surveying and MSc.in Economic & Environmental Modelling."
            },
            "4":
            {
                name: "Karl Duffy",
                linkedin: "https://uk.linkedin.com/in/karlduffy",
                position: "Non-Executive Director",
                photo: karl,
                company: (<a href='https://www.three.ie/'>Three Ireland</a>),
                industry: "Information & Communication Technology",
                bio: "Karl is a co- founder and, having returned to his native Dublin, has recently stepped down as Chairman of LIBS, having previously operated as the network’s Vice Chairman for the five years from the creation of the network in 2009." +
                    "Karl has recently taken a role as Head of Enterprise and Public Sector with Three Ireland and moved back to Dublin in June 2018. Prior to leaving London Karl was an Executive in BT Group where he is responsible for the Transformation of Openreach Ethernet Delivery in the UK.He has seven years’ experience in enterprise sector strategy, delivery and transformation as well as business management and operations for complex contracts.He is a member of the BT Group fast track talent programme and a member of the Irish Government’s Future Leader Programme." +
                    "Karl has a first class honours MSc in Management Consultancy from the Smurfit School of Business, Dublin."
            },
            "8":
            {
                name: "Brian O’Connor",
                linkedin: "https://www.linkedin.com/in/brian-ocnnr/",
                position: "Chair",
                photo: brian,
                company: (<a href='https://emitwise.com/'>Emitwise</a>),
                industry: "Climate Technology",
                bio: "Brian works a Growth Manager for Klaviyo in London. Previously he spent four years at Dropbox's EMEA HQ in Dublin across several roles. Brian holds a first class honours BA in Biochemistry from TCD."
            },
            "13": {
                name: "Charles O'Doherty",
                linkedin: "https://www.linkedin.com/in/charlesodoherty/",
                position: "Board Member",
                photo: charles,
                company: (<a href='https://togethergroup.com/'>Together Group</a>),
                industry: "Airlines",
                bio: "Charles joined the Board in May 2020 and is currently the Head of Finance for LIBS. Charles currently works for International Airlines Group in their Treasury team, having also spent some time at a start-up and working in consulting.",
            },
            "14":
            {
                name: "Neil Pimley",
                linkedin: "https://www.linkedin.com/in/neilpimley",
                position: "Board Member",
                photo: neil,
                company: (<a href='https://www.angloamerican.com/'>Anglo American</a>),
                industry: "Mining",
                bio: "Neil currently works for Anglo American Plc in London on a ESG application that tracks the supply chain of products from extraction to the end customer and stores the journey on a block chain.",
            },
            "18": {
                name: "Richard Owens",
                linkedin: "https://www.linkedin.com/in/richard-owens-0a9b09ba/",
                position: "Board Member",
                photo: richard,
                company: (<a href='https://www.easyjet.com/'>Easyjet</a>),
                industry: "Airline",
                bio: "",
            },
            "22": {
                name: "Jack Murphy",
                linkedin: "https://www.linkedin.com/in/jack-murphy-508bba108/",
                position: "Board Member",
                photo: jack,
                company: (<a href='https://www.zerogravity.co.uk/employers'>Zero Gravity</a>),
                industry: "IT/Education",
                bio: "Jack is a Senior Software Engineer at Zero Gravity, an early-stage social mobility start-up. Having moved to London in January 2020, Jack joined LIBS to expand his network and contribute to the organisation's mission of supporting the Irish professional community. As a dedicated advocate for social mobility and mentorship, Jack hopes to bring his technical expertise toward helping expand the reach of LIBS.",
            },
            "23": {
                name: "Niamh Linehan",
                linkedin: "https://www.linkedin.com/in/niamh-linehan-7b641633/",
                position: "Board Member",
                photo: niamh,
                company: (<a href='https://www.defactor.com/'>Defactor Labs</a>),
                industry: "Finance",
                bio: "",
            },
            "24": {
                name: "Réailtín Walsh",
                linkedin: "https://www.linkedin.com/in/reailtin-walsh/",
                position: "Assoc. Board Member",
                photo: reailtin,
                company: (<a href='https://www.wallacespace.com/'>Wallacespace</a>),
                industry: "Workspaces/Events",
                bio: "",
            },
            "25": {
                name: "Cáit Connaughton",
                linkedin: "https://www.linkedin.com/in/cait-connaughton/",
                position: "Board Member",
                photo: cait,
                company: (<a href='https://firstderivative.com/'>First Derivative</a>),
                industry: "Fintech",
                bio: "Cáit works as a Business Development Manager at First Derivative. Cáit joined First Derivative on their Graduate Programme as a Financial Engineer in Dublin. Cáit holds a Bachelor of Commerce from University of Galway.",
            },
            "26": {
                name: "Sarah Horgan",
                linkedin: "https://www.linkedin.com/in/sarahhorgan88/",
                position: "Board Member",
                photo: sarah,
                company: (<a href='https://www.thewisdomcouncil.com'>The Wisdom Council</a>),
                industry: "Asset and Wealth Management. ",
                bio: "Sarah is a BD Manager for The Wisdom Council, an Asset and Wealth Management market intelligence company. Her background is in Client Services in Financial Services recruitment and Asset Management recruitment in London, where she has been based since 2022. Prior to this, she was working in Tech Recruitment in Dublin. She holds a Commerce Degree from University College Cork. Sarah is on the events team and is passionate about utilising and leveraging the Irish network of professional expats here in London.",
            },
            "27": {
                name: "Emma Shields",
                linkedin: "https://www.linkedin.com/in/emmashields/",
                position: "Board Member",
                photo: emma,
                company: (<a href='https://www.localyze.com/'>Localyze</a>),
                industry: "Global Mobility",
                bio: "Emma currently works as UK Market Lead for global mobility firm Localyze in London having previously worked in fintech firms Pleo and Square in Lisbon and Dublin. Emma holds a Bachelor degree in Commerce International from UCD. She is supporting the Community function to ensure our sponsors and wider alumni groups maximise the benefits of LIBS.",
            },
            "28": {
                name: "Sorcha O'Connor",
                linkedin: "https://www.linkedin.com/in/sorcha-oconnor-183108221/",
                position: "Assoc. Board Member",
                photo: sorcha,
                company: (<a href='https://autogenai.com/'>AutogenAI</a>),
                industry: "Global Mobility",
                bio: "Sorcha O'Connor is a Business Development Representative for AutogenAI. She is currently based in London and has been since 2022. Before joining AutogenAI, Sorcha worked with a variety of companies and roles from Riley, the Irish eco-friendly period care company to Aer Lingus as a cabin crew.  Sorcha is brimming with excitement to embark on her journey as an Event Management Associate at LIBS. Not only is she passionate about harnessing the potential of AI technology to meet business targets, but she is also eager to use these skills to contribute significantly to the growth and success of LIBS.",
            }
        }
    }


    render() {
        var boardMembers = [];
        var board = this.getBoard();
        Object.keys(board).forEach(function (key) {
            board[key]['key'] = key;
            boardMembers.push(board[key]);
        });

        return (
            <section className="bg-secondary">
                <div className="container">
                    <Row>
                        <h2 className="text-white">The Board</h2>
                    </Row>
                    <Row>
                        {boardMembers.map((member, i) => {
                            return <Col md="3" className="mt-4" key={i}>
                                <Card className="board-bio">
                                    <CardImg top width="100%" src={member.photo} alt={member.name} />
                                    <CardBody>
                                        <CardTitle><strong>{member.name}</strong>
                                            <br /><small>{member.position}</small>
                                        </CardTitle>
                                        <CardSubtitle className="board-company">Company: {member.company}</CardSubtitle>
                                    </CardBody>
                                    <CardFooter>
                                        <ReactGA.OutboundLink eventLabel={'board-' + member.linkedin} to={member.linkedin}>
                                            <img src={linkedin} className="board-linkedin float-right mt-1" alt="..." />
                                        </ReactGA.OutboundLink>
                                        <i
                                            id={"popover-" + member.key}
                                            className="fa fa-info-circle fa-lg"
                                            name="circle-info"
                                            onMouseOver={() => this.togglePopover(member.key)}
                                            onMouseOut={() => this.togglePopover(member.key)}
                                        ></i>
                                    </CardFooter>
                                </Card>
                                {this.state.popoverOpen === member.key && (
                                    <Popover
                                        placement="bottom"
                                        isOpen={this.state.popoverOpen === member.key}
                                        target={"popover-" + member.key}
                                        toggle={() => this.togglePopover(member.key)}
                                    >
                                        <PopoverHeader>{member.name}</PopoverHeader>
                                        <PopoverBody>
                                            {this.getBoardMemberBio(member.key)}
                                        </PopoverBody>
                                    </Popover>
                                )}
                            </Col>
                        })}
                    </Row>

                    <Modal isOpen={false} toggle={this.toggle} className="lg-modal">
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <ModalBody>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={this.toggle}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </section>
        )
    }
}
