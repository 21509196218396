import React, { Component } from 'react';
import ReactGA from 'react-ga';
import facebook from '../images/icons/facebook.svg';
import instagram from '../images/icons/instagram.svg';
import linkedin from '../images/icons/linkedin.svg';
import medium from '../images/icons/medium.svg';
import spotify from '../images/icons/spotify.svg';
import twitter from '../images/icons/new-twitter.svg';
import eventbrite from '../images/icons/eventbrite.svg';

export default class Podcasts extends Component {
    render() {
        return (
            <div className="sticky-sidebar">
                <ReactGA.OutboundLink eventLabel="linkedin" to="https://www.linkedin.com/company/london-irish-business-society" title="Follow us on LinkedIn" target="_blank"><img src={linkedin} className="social-icon" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="x" to="https://twitter.com/LondonIrishBiz" title="Follow us on X" target="_blank"><img src={twitter} className="social-icon" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="spotify" to="https://open.spotify.com/show/7IbGpCpWCxr0O2sQmxCK62" title="Listen to us on Spotify" target="_blank"><img src={spotify} className="social-icon" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="eventbrite" to="https://www.eventbrite.co.uk/o/london-irish-business-society-10632071148" title="Follow us on Eventbrite" target="_blank"><img src={eventbrite} className="social-icon" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="facebook" to="https://www.facebook.com/LondonIrishBusinessSociety/" title="Follow us on Facebook" target="_blank"><img src={facebook} className="social-icon" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="medium" to="https://medium.com/@LondonIrishbusinesssociety" title="Follow us on Medium" target="_blank"><img src={medium} className="social-icon d-none d-sm-block" alt="..." /></ReactGA.OutboundLink>
                <ReactGA.OutboundLink eventLabel="instagram" to="https://www.instagram.com/londonirishbusinesssociety" title="Follow us on Instagram" target="_blank"><img src={instagram} className="social-icon" alt="..." /></ReactGA.OutboundLink>
            </div>
        )
    }
}
