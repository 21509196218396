import React, { Component } from 'react';
import ReactGA from 'react-ga';
import spotify_sm from '../images/spotify_sm.png';
import itunes_sm from '../images/apple_podcasts_sm.png';
import google_sm from '../images/google_sm.png';

export default class Podcasts extends Component {
    render() {
        return (
            <section className="bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <h2>Official Podcast</h2>
                            <h3>Leadership Series</h3>
                            <p>In our leadership series we interview top Irish business leaders with a connection to London. </p>
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <h5>Episode 15 - International Women’s Day</h5>
                                    <p>Laura is joined by Emma Kennedy CEO of Grafter, India Healy O’Connor, CEO of Xelda and Rachel Hennessy, CEO of Happy Days. In celebration of International Womens’ Day 2022, the panel have an open and honest discussion on what motivated them to found their start-ups, the challenges that they have faced in their careers to date and how to tackle bias in the workplace. </p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-15-itunes" to="https://podcasts.apple.com/us/podcast/international-womens-day-2022/id1514676720?i=1000553209968"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-15-spotify" to="https://open.spotify.com/episode/1BeVaiLhPHdA16Gc6jGx2a"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-15-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC0xMDIwNDcxMQ?sa=X&ved=0CAUQkfYCahcKEwiA1uOZ3bb2AhUAAAAAHQAAAAAQCg&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 14 - Cathriona Hallahan, Managing Director of Microsoft Ireland‬</h5>
                                    <p>Brian is joined by Cathriona Hallahan, Managing Director of Microsoft Ireland. Cathriona discusses her career journey with Microsoft, her experience leading a large organisation during the Covid-19 pandemic, and her passion for mentorship. Subscribe to the podcast to receive new episodes.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-14-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-14-cathriona-hallahan/id1514676720?i=1000516488229"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-14-spotify" to="https://open.spotify.com/episode/0ATjYLIElWo9E0vHr7i0Jk"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-14-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC04MzAxODM1?sa=X&ved=0CAUQkfYCahcKEwjwoYXr5Y7wAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 13 - Daniel Mulhall‬, Ambassador of Ireland to the United States</h5>
                                    <p>Daniel discusses the impact of President Biden’s Irish heritage on US-Ireland relations, engaging with the Irish diaspora during the COVID-19 pandemic, and reflections on his time as Ambassador to the United Kingdom. Subscribe to the podcast to receive new episodes.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-13-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-13-daniel-mulhall/id1514676720?i=1000513382954"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-13-spotify" to="https://open.spotify.com/episode/3QZXSRkwIJHQiNj1dAzk7r"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-13-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC04MTQ5OTI2?sa=X&ved=0CAUQkfYCahcKEwjwgd7KvrnvAhUAAAAAHQAAAAAQEQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5> Episode 12 - Carin Bryan‪s‬, CEO at J.P. Morgan Ireland</h5>
                                    <p>Carin discusses her career at J.P. Morgan, the importance of diversity and inclusion, and building relationships in a remote world.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-12-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-12-carin-bryans/id1514676720?i=1000511766239"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-12-spotify" to="https://open.spotify.com/episode/00mJKNhzFp28KkQhkVDZtf"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-12-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC04MDgxNTg0?sa=X&ved=0CAUQkfYCahcKEwiQ5fa7krLvAhUAAAAAHQAAAAAQAw&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 11 - Donough Kilmurray, Chief Investment Officer at Davy Group</h5>
                                    <p>Donough discusses his journey to becoming a Managing Director at Goldman Sachs, reflections on his time in London, and what it’s been like leading a team through the past 12 months.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-11-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-11-donough-kilmurray/id1514676720?i=1000511271135"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-11-spotify" to="https://open.spotify.com/episode/71V7SrXdamG4P0NwnDfKgB"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-11-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC04MDU4OTM0?sa=X&ved=0CAYQkfYCahcKEwiQ5fa7krLvAhUAAAAAHQAAAAAQBg&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 10 - Bernard Brogan, Founder of PepTalk and sports personality - 6 Oct 2020</h5>
                                    <p>Bernard discusses his thoughts on employee wellbeing, what his football career has taught him about leadership, and his advice on resilience.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-10-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-10-bernard-brogan/id1514676720?i=1000493838071"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-10-spotify" to="https://open.spotify.com/episode/2WVwkeZAZTi0kZS18G7IM2"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-10-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC01NzYzMDYx?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 9 - Fiona Mullan, Chief People Officer at Ding.com - 23 Sep 2020 </h5>
                                    <p>Brian is joined by Fiona Mullan, Chief People Officer at Ding.com. Fiona discusses her advice on leadership during COVID-19, the value of international experience during your career, and her involvement with a number of not-for-profit boards.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-9-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-9-fiona-mullan/id1514676720?i=1000492346722"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-9-spotify" to="https://open.spotify.com/episode/1idEQYiHototMOE5lbcqiq"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-9-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC01NTcwMjg0?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 8 - Fiona Gallagher, CEO of Wells Fargo Bank International - 7 Sep 2020 </h5>
                                    <p> Brian is joined by Fiona Gallagher, CEO of Wells Fargo Bank International. Fiona discusses her advice on managing your career, diversity and inclusion, and maintaining employee engagement while working remotely. </p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-8-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-8-fiona-gallagher/id1514676720?i=1000490446449"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-8-spotify" to="https://open.spotify.com/episode/2emm7NvDNnoEQObowpmS9d"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-8-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC01MzMwOTg2?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 7 -  Adrian O'Neill, Ireland's Ambassador to the UK - 19 Aug 2020 </h5>
                                    <p>Brian is joined by Adrian O'Neill, Ireland's Ambassador to the UK. Adrian discusses the relationship between Ireland and the UK, the successes of Irish leaders on the European and world stages, and the importance of Irish people’s ability to engage with other cultures.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-7-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-7-adrian-oneill/id1514676720?i=1000488622556"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-7-spotify" to="https://open.spotify.com/episode/2Wh90TmoY57NuhpsycGVZt"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-7-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC01MDU5NTk4?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 6 -  Gerald Fitzgerald, CEO of Morgan McKinley - 4 Aug 2020 </h5>
                                    <p>Brian is joined by Gerald Fitzgerald, CEO of Morgan McKinley. Gerald discusses the importance of continuously reinventing your business, the future of remote work, and the Irish government’s response to the Coronavirus pandemic.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-6-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-6-gerald-fitzgerald/id1514676720?i=1000487142956"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-6-spotify" to="https://open.spotify.com/episode/2IYfPS3VYKqzh3fO4FLOzm"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-6-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC00ODQ0NTQ2?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 5 -  Caitríona O'Kelly, CFO at Davy Group - 20 July 2020 </h5>
                                    <p>Brian is joined by Caitríona O'Kelly, CFO at Davy Group. Caitríona discusses opportunities in Ireland's financial services sector, her decision to return to Ireland after 16 years of working abroad, balancing work and parenthood, and what leaders can do to address gender imbalance in the workplace.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-5-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-5-caitr%C3%ADona-okelly/id1514676720?i=1000485596146"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-5-spotify" to="https://open.spotify.com/episode/6H9qNGmIQvWVoHcFAfCaeE"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-5-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC00NjQ2MjM0?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 4 - Rachel Hussey, Partner and Head of Business Development at Arthur Cox - 15 July 2020 </h5>
                                    <p>Brian is joined by Rachel Hussey, Partner and Head of Business Development at Arthur Cox and Chair of the 30% Club Ireland. Rachel shares her thoughts on mentorship, leading remote teams, and diversity in the Irish business community.</p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-4-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-4-rachel-hussey/id1514676720?i=1000483047892"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-4-spotify" to="https://open.spotify.com/episode/6pZTM5J5fFAC4svr4cqeX3"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-4-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC00NDYzNDUw?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 3 - Marc Murphy, founder and CEO of Fenergo - 23 June 2020 </h5>
                                    <p>Brian is joined by Marc Murphy, founder and CEO of Fenergo. Marc discusses his personal growth journey, building the right leadership team, and opportunities for entrepreneurs in 2020.
                                    </p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-3-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-3-marc-murphy/id1514676720?i=1000479372834"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-3-spotify" to="https://open.spotify.com/episode/0mlS7AVw3uGbMUmSaFa4gp"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-3-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC00MjgzMTM1?sa=X&ved=0CAUQkfYCahcKEwjw2fL6w7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 2 - Seamus Keating (CEO First Derivatives) - 8 June 2020 </h5>
                                    <p>Brian is joined by Seamus Keating, CEO of First Derivatives. Seamus discusses his international career, his experience leading a global technology company from both the C-suite and the board, and the importance of investing in company culture.
                                    </p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-2-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-2-seamus-keating/id1514676720?i=1000477313788"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-2-spotify" to="https://open.spotify.com/episode/1RWleNEmM1Z71ry5652Pst"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-2-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC00MDg1Mzk5?sa=X&ved=0CAUQkfYCahcKEwioo4TUw7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5>Episode 1 - Stephen Kelly (CEO Sage) - 19 May 2020</h5>
                                    <p>
                                        Brian is joined by Stephen Kelly. Stephen takes us on his leadership journey as a serial CEO, beginning as the local paperboy, graduating to a
                                        sales manager at Oracle and on to the CEO of Sage. He shares his thoughts on compassionate capitalism, work-life balance and leading through a
                                        crisis. Follow Stephen Kelly <a href="https://twitter.com/SKellyCEO">@SKellyCEO</a>.
                                    </p>
                                    <p>
                                        <strong className="float-right">listen on:
                                            <ReactGA.OutboundLink eventLabel="podcast-1-itunes" to="https://podcasts.apple.com/us/podcast/libs-leadership-series-ep-1-stephen-kelly/id1514676720?i=1000475348542"><img src={itunes_sm} title="Listen on iTunes" alt="Listen on iTunes" className="ml-3 mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-1-spotify" to="https://open.spotify.com/episode/2IJ7pYMBVGdVIxhVwPjT99"><img src={spotify_sm} title="Listen on Spotify" alt="Listen on Spotify" className="mr-3 size-32x32" /></ReactGA.OutboundLink>
                                            <ReactGA.OutboundLink eventLabel="podcast-1-google" to="https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xMDMyMjQ3LnJzcw/episode/QnV6enNwcm91dC0zODI0OTM2?sa=X&ved=0CAUQkfYCahcKEwioo4TUw7PsAhUAAAAAHQAAAAAQAQ&hl=en-GB"><img src={google_sm} title="Listen on Google Podcasts" alt="Listen on Google Podcasts" className="size-32x32" /></ReactGA.OutboundLink>
                                        </strong>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
