import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Row, Col, } from 'reactstrap';
const axios = require("axios");

export default class Blog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            articles: [],
            isLoading: false,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        axios.get('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@LondonIrishbusinesssociety')
            .then((result) => {
                const posts = result.data.items.filter(item => item.categories.length > 0).slice(0, 6);
                this.setState({
                    articles: posts,
                    isLoading: false
                });
            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    error,
                    isLoading: false
                });
            });
    }

    render() {
        const { articles, isLoading } = this.state;

        return (
            <section>
                <div className="container">
                    <Row>
                        <Col><h2>Blog</h2> </Col>
                    </Row>
                    {(isLoading) => {
                        return <p>Loading blog posts...</p>;
                    }}
                    {articles.map((article, i) => {
                        return (<div className="card mb-3" key={i}>
                            <div className="row no-gutters">
                                <div className="col-md-2 pt-4">
                                    <img src={article.thumbnail} className="card-img" alt="..." />
                                </div>
                                <div className="col-md-10">
                                    <div className="card-body">
                                        <ReactGA.OutboundLink eventLabel={'blog-' + i} to={article.link}><h5 className="card-title">{article.title}</h5></ReactGA.OutboundLink>
                                        <p className="card-text"><small className="text-muted">Published {new Date(article.pubDate).toLocaleDateString()}</small></p>
                                        <p className="card-text">{article.categories.map((c, j) => {
                                            return (<span className="badge badge-secondary mr-2" key={`${i}-${j}`}>{c}</span>)
                                        })}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    })}
                </div>
            </section>
        )
    }
}
