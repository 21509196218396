import React, { Component } from 'react';

export default class NoPage extends Component {
    render() {
        return (
            <div>
                <section>
                    <div className="container">
                        <h2>Page not found</h2>
                    </div>
                </section>
            </div>
        )
    }
}