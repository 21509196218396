import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import 'whatwg-fetch';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            emailAddress: '',
            message: '',
            loading: false,
            messageSent: false,
            errorMessage: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetMessage = this.resetMessage.bind(this);
    }

    resetMessage() {
        this.setState({
            fullName: '',
            emailAddress: '',
            message: '',
            loading: false,
            messageSent: false,
            errorMessage: ''
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var _this = this;
        this.setState({
            loading: true,
            messageSent: false,
            errorMessage: ''
        });


        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        fetch('https://us-central1-london-irish-business-society.cloudfunctions.net/app/api/contact-us', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(this.state)
        }).then(function () {
            _this.setState({
                loading: false,
                messageSent: true,
            });
        }).catch(function (error) {
            _this.setState({
                loading: false,
                errorMessage: error
            });
        });
    }


    render() {
        return (
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <h2>Contact Us</h2>
                            {!this.state.messageSent &&
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group row">
                                        <label htmlFor="fullName" className="col-sm-3 col-form-label">Full name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control" name="fullName"
                                                disabled={this.state.loading} required
                                                placeholder="Enter your name" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label htmlFor="emailAddress" className="col-sm-3 col-form-label">Email address</label>
                                        <div className="col-sm-9">
                                            <input type="email" className="form-control" name="emailAddress"
                                                disabled={this.state.loading} required
                                                placeholder="Enter your email address" onChange={this.handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <label htmlFor="exampleFormControlTextarea1">Message</label>
                                            <textarea className="form-control" name="message" rows="3"
                                                disabled={this.state.loading} required
                                                onChange={this.handleInputChange}></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <button type="submit" className="btn btn-lg float-right btn-light"
                                                disabled={this.state.loading}>
                                                {this.state.loading &&
                                                    <FontAwesome name='circle-o-notch' spin />
                                                }
                                Send</button>
                                        </div>
                                    </div>
                                </form>
                            }
                            {this.state.messageSent &&
                                <div className="alert alert-secondary" role="alert">
                                    Your message has been sent <button type="button" className="btn btn-primary float-right" onClick={this.resetMessage}>Send another message</button>
                                </div>
                            }


                        </div>
                    </div>
                </div>
            </section>
        )
    }
}


