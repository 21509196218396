import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Event } from './Tracking';
import logo from '../images/LIBS_FULL LOGO_GREEN.png';
import mmk from '../images/new-mmk-logo.png';
import gteic from '../images/gteic-logo.png';

export default class About extends Component {
    render() {
        return (
            <section className="bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-md-5">
                            <img src={logo} className="img-fluid mt-2" alt="London Irish Business Society" />
                            <a className="btn btn-block btn-join mt-5 mb-5" href="#join" title="LIBS is one of the largest free to join not-for-profit networks for Irish professionals in London">Join LIBS</a>

                            <ReactGA.OutboundLink eventLabel="visit-sponsor-mmk" to="https://www.morganmckinley.ie/">
                                <img src={mmk} className="float-left small-logo mb-5 mb-md-0 mr-2"
                                    alt="Morgan McKinley is a global recruitment agency specialising in recruiting highly skilled professionals across a wide number of industries."
                                    title="Morgan McKinley is a global recruitment agency specialising in recruiting highly skilled professionals across a wide number of industries."
                                />
                            </ReactGA.OutboundLink>

                            <ReactGA.OutboundLink eventLabel="visit-sponsor-gteic" to="https://udaras.ie/en/business/gteic/">
                                <img src={gteic} className="float-left small-logo mb-5 mb-md-0"
                                    alt="gteic Gréasán Digiteach na Gaeltachta is a network of 31 innovation &amp; digital hubs."
                                    title="gteic Gréasán Digiteach na Gaeltachta is a network of 31 innovation &amp; digital hubs."
                                />
                            </ReactGA.OutboundLink>

                        </div>
                        <div className="col-xs-12 col-md-7 d-xs-none">
                            <p>
                                <strong>LIBS</strong> was established in December 2009 to provide a forum for Irish business professionals to network and share ideas.
                            </p>
                            <p>Our events provide a platform for the business community to get together and address some of the problems that face the Irish economy, and the businesses that exist within it. In unifying these business minds we hope to generate long lasting partnerships and create new opportunities through the ideas we share.</p>

                            <p>With the rapid expansion of the Irish community in the United Kingdom, it is becoming increasingly important to maintain and develop a strong business community which can embed itself in a foreign context. To find out more about our next event, visit the events section.</p>

                            <p>If you are an individual or business looking to get involved with LIBS, please contact us or sign up to our members list by joining <a href="#join" onClick={() =>
                                Event("JOIN", "Go to join section", "VISIT_JOIN_LIBS")
                            }>here</a>.</p>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
