import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import SocialSidebar from './SocialSidebar';
import Footer from './Footer';

export default class Layout extends Component {

    render() {
        return (
            <div>
                <NavBar />
                <SocialSidebar />
                <Outlet />
                <Footer />
            </div>
        )
    }
}