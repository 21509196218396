import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Contact from './Contact';
import About from './About';
import Events from './Events';
import Join from './Join';
import Partners from './Partners';

export default class Home extends Component {
  render() {
    return (
      <div>
        <ScrollableAnchor id={'about'}>
          <About />
        </ScrollableAnchor>
        <ScrollableAnchor id={'join'}>
          <Join />
        </ScrollableAnchor>
        <ScrollableAnchor id={'contact'}>
          <Contact />
        </ScrollableAnchor>
      </div>
    )
  }
}