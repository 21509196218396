import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Footer = (props) => {
    const {
        className = 'modal-lg'
    } = props;

    const [privacyModal, setPrivacyModal] = useState(false);
    const [conductModal, setConductModal] = useState(false);

    const togglePrivacy = () => setPrivacyModal(!privacyModal);
    const toggleConduct = () => setConductModal(!conductModal);

    return (
        <footer className="py-5 bg-dark text-white">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        &copy; London Irish Business Society 2024
                    </div>
                    <div className="col-xs-12 col-md-6 text-right">
                        <a href="Javascipt:void(0)" className="text-white" onClick={toggleConduct}>Member Code of Conduct</a>
                        &nbsp;|&nbsp;
                        <a href="Javascipt:void(0)" className="text-white" onClick={togglePrivacy}>Privacy Policy</a>
                    </div>
                </div>
            </div>
            <Modal isOpen={privacyModal} toggle={togglePrivacy} className={className}>
                <ModalHeader toggle={togglePrivacy}>Privacy Policy</ModalHeader>
                <ModalBody className="small-print">
                    <p>We collect certain information or data about you when you join or use LIBS.</p>

                    <p>We collect:</p>
                    <ul>
                        <li>questions, queries or feedback you leave, including your email address if you contact LIBS Events Ltd</li>
                        <li>your email address and subscription preferences when you sign up to our mailing list, and how you use our emails – for example whether you open them and which links you click on</li>
                        <li>your IP address, and details of which version of web browser you used</li>
                        <li>information on how you use the site, using cookies, Facebook Pixels and page tagging techniques</li>
                        <li>The data you provide us when you sign up to our email mailing list</li>
                    </ul>

                    <p>This data can be viewed by authorised people on the LIBS team and our suppliers, to:</p>

                    <ul>
                        <li>improve the site by monitoring how you use it</li>
                        <li>gather feedback to improve our services, for example, our communications and use of third-party services, e.g. Eventbrite</li>
                        <li>respond to any feedback you send us if you’ve asked us to</li>
                        <li>send emails to users about upcoming events, recently delivered events and future LIBS plans</li>
                        <li>Produce aggregate data on the makeup of the Society Membership</li>
                        <li>We also assemble aggregate data on the demographics and makeup of the Membership. We use this aggregate data to present to potential sponsors and partners. Aggregage data is not personal data and we do not share any of your personal data with any of our partners or sponsors.</li>
                    </ul>

                    <h6>Where your data is stored</h6>
                    <p>LIBS Events Ltd stores all data collected on our website on secure servers located in the Republic of Ireland, which is within the European Economic Area (EEA).</p>

                    <p>IBS routinely transfers this data to MailChimp, our third-party email provider for the purposes of creating, adding to and maintaining our mailing lists which we use to communicate with members about upcoming events, past events and LIBS news.</p>

                    <p>By submitting your personal data, you agree to this.</p>

                    <h6>When you sign up to our email mailing list</h6>
                    <p>We use a third-party email service, MailChimp, to manage our regular email communications to members and MailChimp act as a data-processor on behalf of LIBS.</p>

                    <p>MailChimp is a US company and has staff based outside the EEA, and stores your data in the US. MailChimp is certified under the EU-US Privacy Shield framework. When you sign up to our email alerts, you agree to your data being stored in this way.</p>

                    <p>As a subscriber to our email alerts, we may contact you from time to time to ask for your feedback on how to improve our email alert service.</p>

                    <p>As a data processor on behalf of LIBS, MailChimp will use your information to send you email alerts if you request them. You can find out more about how MailChimp collects and stores your information.</p>

                    <h6>Keeping your data secure</h6>
                    <p>Sending information over the internet is generally not completely secure, and we can’t guarantee the security of your data while it’s in transit, however, to give users greater confidence the LIBS website is protected via a HTTPS connection, verified by SSL certificate, which provides encryption for data entered on the site. However, no data transmitted over the internet can be guaranteed to be 100% safe and LIBS do not accept liability for any data in transit and any data you send is at your own risk.</p>

                    <p>We take reasonable steps to secure your Personal Data and have operational procedures and security features in place to keep your data secure once we receive it.</p>

                    <p>All data collected via the LIBS website is stored in a secure database hosted by Blacknight, a company located in the Republic of Ireland. Blacknight provides excellent physical and cyber security protection to protect their customer’s data.</p>

                    <h6>Disclosing your information</h6>
                    <p>We may pass on your personal information if we have a legal obligation to do so, or if we have to enforce or apply our terms of use and other agreements. This includes exchanging information with other government departments for legal reasons.</p>

                    <p>We won’t share your information with any other organisations for marketing, market research or commercial purposes, and we don’t pass on your details to other websites.</p>

                    <h6>Your rights</h6>
                    <p>You can find out what information we hold about you, and ask us [link to contact form] not to use any of the information we collect.</p>

                    <p>Under the European Union General Data Protection Regulation (GDPR) you (the data subject) have several rights in respect of your data and how LIBS processes it:</p>

                    <p>You have the right to request whether or not your personal data is being processed by LIBS Events Ltd.</p>

                    <p>If your personal data is being processed you have the right to access this data and the following information:</p>

                    <ul>
                        <li>The purposes of the processing</li>
                        <li>The categories of personal data concerned</li>
                        <li>The recipients or categories of recipient to whom the personal data has been or will be disclosed</li>
                        <li>The envisaged period for which the personal data will be stored</li>
                        <li>If your personal data has not been collected directly from you, the source</li>
                        <li>The existence of any automated decision-making or profiling and information about the logic involved</li>
                        <li>The appropriate safeguards in place where your data is transferred to any third country</li>
                        <li>You also have the right to have any inaccurate data corrected, or incomplete data completed. You also have a right to obtain a copy of any incomplete or inaccurate data.</li>
                    </ul>

                    <p>You have the right to request that LIBS Events Ltd ceases to process your data and the “right to be forgotten” where, upon your request, LIBS Events Ltd will delete all personal data held about you.</p>

                    <p>You also have the right to request that LIBS Events Ltd restricts the processing of your data and the right to object, at any time, to the use of your data for direct marketing purposes or profiling.</p>

                    <p>If you’ve signed up for email alerts, you can unsubscribe or change your settings at any time. Alternatively you may contact the LIBS team via email to request that you are unsubscribed, or to exercise any of your rights listed above.</p>

                    <p>Please contact: Conor Redmond – conor@li-bs.co.uk</p>

                    <p>LIBS Events Ltd does not undertake any automated decision making. All events are prioritised on a first-come, first-served basis. If at any point in the future LIBS Events Ltd engages in automated decision making you have the right to object to automated decisioning and have your data processed manually.</p>

                    <h6>Further information about your rights and relevant supervisory authority</h6>
                    <p>You may like to read more about your rights and what you may request LIBS Events Ltd do with your personal data.</p>

                    <p>For further information please visit:</p>
                    <ul>
                        <li><a href="http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG&toc=OJ:L:2016:119:TOC">http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG&toc=OJ:L:2016:119:TOC</a></li>
                        <li><a href="https://ico.org.uk/">https://ico.org.uk/</a></li>
                    </ul>

                    <h6>Supervisory Authority:</h6>
                    <p>LIBS Events Ltd is based in the UK and as such will be subject to supervision by the Information Commissioner’s Office (ICO) https://ico.org.uk/</p>

                    <p>You may report any concerns to the ICO at 0303 123 1113, for more details visit https://ico.org.uk/concerns/</p>

                    <h6>Legal basis of processing</h6>
                    <p>LIBS Events Ltd is a fully volunteer run organisation that provides events for our members. Membership is free of charge and open to all. Membership is availed of by signing up to the LIBS mailing list via http://www.li-bs.co.uk/join-libs</p>

                    <p>Signing up to the mailing list is not required in order to declare yourself a member of LIBS, or to attend any of our events. However, we release information about our events and invitations to avail of free tickets via our email platform first, before communicating via social media or creating a post on our website.</p>

                    <p>LIBS Events Ltd thus relies on the consent of it’s members, who have opted into receiving communications from LIBS Events Ltd, as the legal basis for processing personal data.</p>

                    <p>Members are advised that they can withdraw consent to process this data at any time. A link to withdraw consent is included in all of our email correspondence.</p>

                    <h6>Links to other websites</h6>
                    <p>The LIBS website contains links to other websites.</p>

                    <p>This privacy policy only applies to LIBS Events Ltd and doesn’t cover other government services and transactions that we link to. These services, such as Eventbrite, have their own terms and conditions and privacy policies.</p>

                    <h6>Following a link to another website</h6>
                    <p>If you go to another website from this one, read the privacy policy on that website to find out what it does with your information.</p>

                    <h6>Following a link to LIBS from another website</h6>
                    <p>If you come to LIBS from another website, we may receive information from the other website. We don’t use this data. You should read the privacy policy of the website you came from to find out more about this.</p>

                    <h6>Additional services used by, but not controlled by LIBS</h6>
                    <p>LIBS uses the following third-party services to manage our events. LIBS Events Ltd do not control these services and consenting to use these third-party services is not required to be a member of LIBS, however, all our events are ticketed events with limited tickets available. In order to have the highest possibility of securing tickets members are encouraged to ensure that their email is securely stored on our mailing list and the use of Eventbrite is encouraged to easily secure tickets to events.</p>

                    <p>Members are advised to check the Privacy Policy of each supplier listed below:</p>

                    <p><strong>Mailchimp – Privacy Policy here</strong><br />
                        Mailchimp is used to administer LIBS Member’s Mailing List</p>

                    <p><strong>Eventbrite – Privacy Policy here</strong><br />
                        Eventbrite is used to offer tickets to LIBS events to LIBS members</p>

                    <p><strong>Google Analytics</strong><br />
                        Google Analytics is used to record certain details of visitors to our website at www.li-bs.co.uk in order to optimise the website and ensure we can provide a reliable service.</p>

                    <p><strong>Hotjar – Privacy Policy here</strong><br />
                        Hotjar is a service used to allow the LIBS team to view amalgamated data of where visitors are viewing content on our website pages, e.g. how far down users scroll on a given page. LIBS team cannot see what individual users are viewing on our website, only amalgamated data for all visitors.</p>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={togglePrivacy}>Close</Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={conductModal} toggle={toggleConduct} className={className}>
                <ModalHeader toggle={toggleConduct}>Member Code of Conduct</ModalHeader>
                <ModalBody className="small-print">

                    <p>All LIBS events are committed to providing a welcoming and harassment-free
                        environment for everyone, regardless of gender, gender identity and expression,
                        age, sexual orientation, disability, physical appearance, body size, race,
                        ethnicity, religion, or other protected status. We do not tolerate harassment in
                        any form and expect all participants to uphold our standards of respect and
                        professionalism.</p>

                    <p><strong>Expected Behaviour:</strong></p>

                    <ol>
                        <li>Treat all participants with respect and kindness.</li>

                        <li>Engage in meaningful and inclusive conversations. </li>

                        <li>Respect personal boundaries and do not engage in any form of harassment or
                            inappropriate behaviour. </li>

                        <li>Use inclusive language and avoid offensive comments or jokes. </li>

                        <li>Be mindful of your actions and how they might affect others. </li>
                    </ol>

                    <p><strong>Unacceptable Behaviour: </strong></p>

                    <ol>
                        <li>Harassment, intimidation, or discrimination in any form. </li>

                        <li>Offensive, demeaning, or derogatory comments or jokes. </li>

                        <li>Invasion of personal space without consent. </li>

                        <li>Any form of unwanted attention or advances. </li>

                        <li>Disruptive or disrespectful behaviour during sessions or presentations.
                        </li>

                        <li>Any other behaviour that creates an uncomfortable or unsafe environment.
                        </li>
                    </ol>

                    <p><strong>Reporting: </strong></p>

                    <p>If you experience or witness any violations of this code of conduct, please
                        report it to a LIBS board member immediately. All reports will be treated
                        seriously and investigated promptly. We are committed to maintaining a safe
                        environment and will take appropriate action to address any violations. </p>

                    <p><strong>Consequences of Unacceptable Behaviour: </strong></p>

                    <p>Participants who engage in unacceptable behaviour may be asked to leave the event
                        and may also face restrictions from participating in future events hosted by our
                        organization. </p>

                    <p>Remember, our networking event is an opportunity to connect, learn, and grow
                        professionally. Let's work together to create a respectful and inclusive
                        atmosphere for everyone. </p>

                    <p>This code of conduct is based on mutual respect and consideration for all
                        participants. By attending this event, you agree to abide by these guidelines
                        and help create a positive experience for everyone involved. </p>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleConduct}>Close</Button>
                </ModalFooter>
            </Modal>
        </footer >
    );
}

export default Footer;
