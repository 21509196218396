import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardImg,
  CardLink,
  CardText,
  CardTitle,
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap';
import mmk from '../images/new-mmk-logo.png';
import lic from '../images/londonirishcentre.svg';

const PartnersContent = () => {
  const [showFullText, setShowFullText] = useState(false);

  const partnerCard = (title, text, img, link) => {
    const trimmedText =
      text.length < 300 || showFullText ? text : text.slice(0, 300) + '...';
    const buttonText = showFullText ? 'Show less...' : 'Show more...';

    return (
      <Card
        style={{ boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)' }}
      >
        <CardImg
          variant="top"
          src={img}
          style={{
            height: '30%',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
          }}
        />
        <CardBody style={{ position: 'relative', height: '70%' }}>
          <CardTitle style={{ fontWeight: 600, fontSize: '2rem' }}>
            {title}
          </CardTitle>
          <CardText>{trimmedText}</CardText>
          {text.length > 300 && (
            <CardLink
              onClick={() => setShowFullText(!showFullText)}
              style={{ cursor: 'pointer', fontWeight: 600 }}
            >
              {buttonText}
            </CardLink>
          )}
        </CardBody>
        {link && (
          <Button
            href={link}
            variant="primary"
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              marginBottom: '1rem',
              marginLeft: '1rem',
            }}
          >
            Visit the {title} website
          </Button>
        )}
      </Card>
    );
  };

  const londonIrishCentreText = () => {
    return 'The London Irish Centre is a charity dedicated to promoting Irish culture and community in London. They provide a range of services, including advice and outreach, cultural events, and community activities.';
  };

  const morganMcKinleyText = () => {
    return (
      'Morgan McKinley are a global professional services recruitment consultancy connecting specialist talent with leading employers across multiple industries and disciplines.' +
      " Founded in Ireland over 25 years ago. If you are thinking of furthering your career internationally you'll be interested to know that Morgan McKinley are over comprised of over 500" +
      ' staff in 10 countries across the UK, Ireland, EMEA, Asia and Australia . Our professional services recruitment'
    );
  };

  return (
    <section className="partners">
      <Container>
        <h2 className="text-center mb-5">Our Partners</h2>
        <Row marginBottom={5}>
          <Col md={12}>
            <h4>Our Partners</h4>
            <p>
              LIBS looks to build lasting relationships with each of its
              partners. Our events offer an opportunity for partners to connect
              with the businesses and individuals that are relevant to their
              industry; while also increasing the company's profile to the right
              people. If you are interested in getting involved with LIBS,
              please Contact Us.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-5">
            {partnerCard(
              'Morgan McKinley',
              morganMcKinleyText(),
              mmk,
              'https://www.morganmckinley.com'
            )}
          </Col>
          <Col md={6}>
            {partnerCard(
              'The London Irish Centre',
              londonIrishCentreText(),
              lic,
              'https://www.londonirishcentre.org'
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PartnersContent;
